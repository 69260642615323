import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Icons, Images } from "assets";
import React, { useState } from "react";
import styles from "./style.module.scss";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { routeConstant } from "shared/routes/routesConstant";

export default function NotificationDropDown() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let notifications = [
    {
      label: "Congratulations!",
      description: "Your subscription plan is now active.",
      img: Images.NotificationSample,
    },
    {
      label: "Book Name",
      description:
        "The book '{Book's Name}' has been assigned to you. Enjoy your reading!",
      subDescription: "by: Teacher Name",
      type: 1,
      img: Images.BookSample1,
    },
    {
      label: "Book Name",
      description:
        "The book '{Book's Name}' has been assigned to you. Enjoy your reading!",
      subDescription: "by: Teacher Name",
      type: 2,
      img: Images.Sample,
    },
  ];

  return (
    <div>
      <button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{
          width: "fit-content",
          border: "none",
          background: "transparent",
          cursor: "pointer",
        }}
      >
        <Icons.NotificationIcon />
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          sx: {
            boxShadow: "none",
            border: "1px solid #e2e2e2",
            borderRadius: "8px",
            zIndex: 999,
          },
        }}
      >
        <div className={styles.notificationHeader}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <label>Notifications</label>
            <label className={styles.counter}>3</label>
          </div>
          <label
            className={styles.readAll}
            onClick={() => {
              navigate(routeConstant.notifications.path);
              handleClose();
            }}
          >
            Read All
          </label>
        </div>
        {notifications?.map((item) => {
          return (
            <MenuItem onClick={handleClose} sx={{ padding: "0px" }}>
              <div className={styles.mainContainer}>
                <div className={styles.menuItems}>
                  <img
                    src={item?.img}
                    className={
                      item?.type === 1
                        ? styles.bookImg
                        : item?.type === 2
                        ? styles.roundImg
                        : styles.img
                    }
                    alt="notification_img"
                  />
                  <div className={styles.headingItems}>
                    <div className={styles.heading}> {item?.label}</div>
                    <div className={styles.description}>
                      {" "}
                      {item?.description}
                    </div>
                    <div className={styles.assigneeContainer}>
                      <img
                        src={Images.AvatarPlaceholder}
                        alt="teacher-pp"
                        className={styles.assigneeImg}
                      />
                      <label className={styles.teacherName}>
                        by: <span>Teacher Name</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className={styles.time}>
                  {moment(
                    "Fri Aug 02 2024 15:27:28 GMT+0500 (Pakistan Standard Time)"
                  )
                    .fromNow()
                    .replace("hours", "hrs")
                    .replace("an hour", "1 hr")
                    .replace("a few seconds", "seconds")
                    .replace("minutes", "mins")
                    .replace("minute", "min")
                    .replace("years", "yrs")
                    .replace("a year", "1 yr")}
                </div>
              </div>
            </MenuItem>
          );
        })}

        {/* <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem> */}
      </Menu>
    </div>
  );
}
