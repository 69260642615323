import { Endpoint } from "shared/utils/endpoints";
import { HTTP_CLIENT } from "../utils/interceptor";
import moment from "moment";

const allReportedUsersService = (
  search?: string,
  activePage?: any,
  itemsPerPage?: number
) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  if (activePage) params.append("page", activePage);
  if (itemsPerPage) params.append("per_page", itemsPerPage.toString());
  const url = `${Endpoint.project_head.allReportedUsers}?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const pendingReportsService = (
  id: number,
  searchText: any,
  activePage: any,
  itemsPerPage: number
) => {
  const params = new URLSearchParams();
  if (searchText) params.append('search', searchText);
  if (activePage) params.append("page", activePage);
  if (itemsPerPage) params.append("per_page", itemsPerPage.toString());
  const url = `${Endpoint.project_head.pendingReports + id
    }?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const historyReportService = (
  id: number,
  search: any,
  activePage: any,
  itemsPerPage: number
) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  if (activePage) params.append("page", activePage);
  if (itemsPerPage) params.append("per_page", itemsPerPage.toString());
  const url = `${Endpoint.project_head.reportHistory + id
    }?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const approveRequestService = (id: number) => {
  return HTTP_CLIENT.post(Endpoint.project_head.approveReport + id);
};

const declineRequestService = (id: number) => {
  return HTTP_CLIENT.post(Endpoint.project_head.declineReport + id);
};

const deleteRequestService = (id: number) => {
  return HTTP_CLIENT.post(Endpoint.project_head.deleteReports + id);
};

const getPublisherProfileService = (id: number) => {
  return HTTP_CLIENT.get(Endpoint.project_head.publisherProfile + id);
};

const getAuthorProfileService = (id: number) => {
  return HTTP_CLIENT.get(Endpoint.project_head.authorProfile + id);
};

const getPublisherBooksServiceProjectHead = (
  id: any,
  search: any,
  activePage: any,
  itemsPerPage: number
) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  if (activePage) params.append("page", activePage.toString());
  if (itemsPerPage) params.append("per_page", itemsPerPage.toString());
  const url = `${Endpoint.project_head.publisherProfile + id + "/books"
    }?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const allMouService = (id: any) => {
  return HTTP_CLIENT.get(Endpoint.project_head.allMOUs + id);
};

const getSingleMOUService = (id: any) => {
  return HTTP_CLIENT.get(Endpoint.project_head.singleMOU + id);
};

const deleteMOUService = (id: any) => {
  return HTTP_CLIENT.post(Endpoint.project_head.deleteMOU + id);
};

const rejectMOUService = (id: any) => {
  return HTTP_CLIENT.post(Endpoint.project_head.rejectMOU + id);
};

const signatureMOUService = (resp: {}) => {
  return HTTP_CLIENT.post(Endpoint.project_head.signAgreement, resp);
};

const getAllUsersProjectHead = (
  search: any,
  activePage: any,
  role: any,
  itemsPerPage: number
) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  if (role) params.append("role", role);
  if (activePage) params.append("page", activePage.toString());
  if (itemsPerPage) params.append("per_page", itemsPerPage.toString());
  const url = `${Endpoint.project_head.getAllUsers}?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const getStatsProjectHead = () => {
  return HTTP_CLIENT.get(Endpoint.project_head.getStats);
};

const getStatsReviewerProjectHead = () => {
  return HTTP_CLIENT.get(Endpoint.project_head.getReviewerStats);
};

const getReviewersListProjectHead = (
  search: any,
  activePage: any,
  itemsPerPage: number
) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  if (activePage) params.append("page", activePage.toString());
  if (itemsPerPage) params.append("per_page", itemsPerPage.toString());
  const url = `${Endpoint.project_head.getReviewersList}?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const getReviewerProfileProjectHead = (id: number) => {
  return HTTP_CLIENT.get(Endpoint.project_head.getReviewerProfile + id);
};

const getReviewerBooksServiceProjectHead = (
  id: any,
  search: any,
  activePage: any,
  itemsPerPage: number
) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  if (activePage) params.append("page", activePage.toString());
  if (itemsPerPage) params.append("per_page", itemsPerPage.toString());
  const url = `${Endpoint.project_head.getReviewerBooks + id + "/books"
    }?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

// Append to Form Body
const getBestSellersService = (
  activePage: any,
  languages: any,
  ageRange: any,
  genres: any,
  sales_type?: any
) => {
  let resp: any = {
    genres: genres,
    age_range_id: ageRange,
    languages: languages,
    page: activePage,
  };
  // const params = new URLSearchParams();

  if (ageRange?.length > 0) {
    resp["age_range_id"] = ageRange;
  }
  if (sales_type?.length > 0) {
    resp["sales_type"] = sales_type;
  }
  let formBody: any = new FormData();
  Object.keys(resp).forEach((key) => {
    if (Array.isArray(resp[key])) {
      resp[key].forEach((value: any, index: any) => {
        formBody.append(`${key}[]`, value);
      });
    } else {
      formBody.append(key, resp[key]);
    }
  });
  // if (activePage) params.append("page", activePage.toString());
  // const url = `${Endpoint.project_head.getBestSellers}?${params.toString()}`;
  // return HTTP_CLIENT.post(url);
  return HTTP_CLIENT.post(Endpoint.project_head.getBestSellers, formBody);
};

const getDashboardStatsProjectHead = () => {
  return HTTP_CLIENT.get(Endpoint.project_head.stats);
};

const getUserGraph = (dates: any) => {
  const params = new URLSearchParams();
  if (dates?.startDate)
    params.append("from", moment(dates?.startDate).format("YYYY-MM-DD"));
  if (dates?.endDate)
    params.append("to", moment(dates?.endDate).format("YYYY-MM-DD"));
  const url = `${Endpoint.project_head.userGraph}?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const getCoinsGraph = (dates: any) => {
  const params = new URLSearchParams();
  if (dates?.startDate)
    params.append("from", moment(dates?.startDate).format("YYYY-MM-DD"));
  if (dates?.endDate)
    params.append("to", moment(dates?.endDate).format("YYYY-MM-DD"));
  const url = `${Endpoint.project_head.coinsGraph}?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const getSoftCopyGraph = (dates: any) => {
  const params = new URLSearchParams();
  if (dates?.startDate)
    params.append("from", moment(dates?.startDate).format("YYYY-MM-DD"));
  if (dates?.endDate)
    params.append("to", moment(dates?.endDate).format("YYYY-MM-DD"));
  const url = `${Endpoint.project_head.softCopyGraph}?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const getMembershipGraphs = (dates: any) => {
  const params = new URLSearchParams();
  if (dates?.startDate)
    params.append("from", moment(dates?.startDate).format("YYYY-MM-DD"));
  if (dates?.endDate)
    params.append("to", moment(dates?.endDate).format("YYYY-MM-DD"));
  const url = `${Endpoint.project_head.membershipGraph}?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const getReviewerStats = (id: any) => {
  return HTTP_CLIENT.get(Endpoint.project_head.reviewerStats + id + "/stats");
};

const blockUserService = (id: any) => {
  return HTTP_CLIENT.post(Endpoint.project_head.blockUser + id);
};

const getTransactionService = (
  searchText: any,
  activeTab: any,
  status?: any,
  from?: any,
  to?: any,
  itemsPerPage?: number
) => {
  const params = new URLSearchParams();
  if (status || status === 0) params.append("status", status);
  if (searchText) params.append("search", searchText);
  if (activeTab) params.append("page", activeTab.toString());
  if (itemsPerPage) params.append("per_page", itemsPerPage.toString());
  if (from && to) {
    params.append("from", from);
    params.append("to", to);
  }
  const url = `${Endpoint.project_head.transactionsList}?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const getTransactionsStatsService = () => {
  return HTTP_CLIENT.get(Endpoint.project_head.getTransactionStats);
};

const getAuthorBooksByProjectHead = (
  id: any,
  search: any,
  activePage: any,
  itemsPerPage: number
) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  if (activePage) params.append("page", activePage.toString());
  if (itemsPerPage) params.append("per_page", itemsPerPage.toString());
  const url = `${Endpoint.project_head.authorProfile + id + "/books"
    }?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

// Newsletter List
const getNewsletterListProjectHead = (search: string = "", activePage: number, per_page: number | null = null) => {
  const params = new URLSearchParams();
  if (activePage) params.append('page', activePage.toString());
  if (search) params.append('search', search);
  if (per_page) params.append('per_page', per_page.toString());

  return HTTP_CLIENT.get(`${Endpoint.project_head.getNewsletterProjectHead}?${params}`);
}

// Delete newsletter 
const deleteNewsLetterProjectHead = (newsLetterId: any) => {
  return HTTP_CLIENT.post(`${Endpoint.project_head.deleteNewsletterProjectHead}${newsLetterId}`);
}

// Subscription history

const getProjectHeadSubscriptionsList = (
  userId: any,
  search: string,
  status: number,
  activePage: number,
  itemsPerPage: number
) => {
  const params = new URLSearchParams();

  if (search) {
    params.append("search", search);
  }
  if (userId) {
    params.append("user_id", userId.toString());
  }
  if (status) {
    params.append("status", status.toString());
  }
  if (activePage) {
    params.append("page", activePage.toString());
  }
  if (itemsPerPage) {
    params.append("per_page", itemsPerPage.toString());
  }
  return HTTP_CLIENT.get(
    `${Endpoint.project_head.subsciptionRequests}?${params.toString()}`
  );
};

const getUserTransactionsList = (userId: number) => {
  const params = new URLSearchParams();
  if (userId) params.append("subscribe_id", userId.toString());
  return HTTP_CLIENT.get(
    `${Endpoint.project_head.getUserTransactionListForProjectHead}?${params.toString()}`
  );
};

// Block Un-Block Partner

const blockPartnerUser = (id: number) => {
  return HTTP_CLIENT.post(
    `${Endpoint.project_head.blockPartnerUser}/${id.toString()}`
  );
}

const unBlockPartnerUser = (id: number) => {
  return HTTP_CLIENT.post(
    `${Endpoint.project_head.unBlockPartnerUser}/${id.toString()}`
  );
}

export {
  allReportedUsersService,
  pendingReportsService,
  historyReportService,
  approveRequestService,
  declineRequestService,
  deleteRequestService,
  getPublisherProfileService,
  getAuthorProfileService,
  getPublisherBooksServiceProjectHead,
  allMouService,
  getSingleMOUService,
  deleteMOUService,
  signatureMOUService,
  getAllUsersProjectHead,
  getStatsProjectHead,
  getStatsReviewerProjectHead,
  getReviewersListProjectHead,
  getReviewerProfileProjectHead,
  getReviewerBooksServiceProjectHead,
  getBestSellersService,
  getDashboardStatsProjectHead,
  getUserGraph,
  getCoinsGraph,
  getSoftCopyGraph,
  getMembershipGraphs,
  getReviewerStats,
  blockUserService,
  getTransactionService,
  getTransactionsStatsService,
  rejectMOUService,
  getAuthorBooksByProjectHead,
  getNewsletterListProjectHead,
  deleteNewsLetterProjectHead,
  getProjectHeadSubscriptionsList,
  getUserTransactionsList,
  blockPartnerUser,
  unBlockPartnerUser
};
