const routeConstant = {
  auth: {
    path: "/",
    title: "Login",
  },
  dashboard: {
    path: "/",
    title: "Dashboard",
  },
  tickets: {
    path: "/tickets",
    title: "Tickets",
  },
  singleTicket: {
    path: "/ticket/:id",
    title: "Ticket",
  },
  publishers: {
    path: "/publishers",
    title: "Publishers",
  },
  chat: {
    path: "/chats",
    title: "Chats",
  },
  books: {
    path: "/books",
    title: "Books",
  },
  author: {
    path: "/authors",
    title: "Authors",
  },
  bookReviewer: {
    path: "/book-reviewers",
    title: "Book Reviewer",
  },
  settings: {
    path: "/settings",
    title: "Settings",
  },
  publisherProfile: {
    path: "/publisher/:id",
    title: "Publisher",
  },
  mou: {
    path: "/mou/:id",
    title: "MOU",
  },
  bookDetail: {
    path: "/book/:id",
    title: "Book Detail",
  },
  bookReview: {
    path: "/book-reviewer/:id",
    title: "Book Reviewer",
  },
  reviewByHOD: {
    path: "/review-by-hod/:id",
    title: "Book Review",
  },
  review: {
    path: "/review/:id",
    title: "Book Review",
  },
  profile: {
    path: "/profile/:id",
    title: "Profile",
  },
  transactions: {
    path: "/transactions",
    title: "Transactions",
  },
  membership: {
    path: "/membership",
    title: "Membership",
  },

  schoolAccounts: {
    path: "/school-accounts",
    title: "School Accounts"
  },
  schoolRequests: {
    path: '/school-requests',
    title: 'School Requests'
  },
  schoolTickets: {
    path: '/school-tickets',
    title: 'Tickets'
  },
  schoolDetail: {
    path: "/school/:id",
    title: "School Details",
  },
  softCopy: {
    path: "/soft-copy",
    title: "Soft Copy",
  },
  hardCopy: {
    path: "/hard-copy",
    title: "Hard Copy",
  },
  booksCatalog: {
    path: "/books-catalog",
    title: "Books catalog",
  },
  customerSupport: {
    path: "/customer-support",
    title: "Customer Support",
  },
  featuredBooks: {
    path: "/featured-books",
    title: "Featured Books",
  },
  requiredBooks: {
    path: "/reported-books",
    title: "Reported Books",
  },
  allMou: {
    path: "/all-mou",
    title: "All MOU",
  },
  bestSellers: {
    path: "/best-sellers",
    title: "Best Sellers",
  },
  reviewersPerformance: {
    path: "/reviewer-performance",
    title: "Reviewer Performance",
  },
  partnerAccount: {
    path: "/partner-account",
    title: "Partner Account",
  },
  reviewerPerformance: {
    path: "/reviewer-performance/:id",
    title: "Reviewer Performance",
  },
  singleAuthor: {
    path: "/author/:id",
    title: "Author",
  },
  reportedUsers: {
    path: "/reported-users",
    title: "Reported Users",
  },
  bookPreview: {
    path: "/book-preview/:id/:slug",
    title: "Book",
  },
  reports: {
    path: "/reports/:id",
    title: "Reports",
  },
  mouHead: {
    path: "/mou-signature/:id",
    title: "MOU",
  },
  reportBookDetails: {
    path: "/report-book-detail/:id",
    title: "Report Book Detail",
  },
  bookRevisions: {
    path: "/book-revisions/:id",
    title: "Book Revisions",
  },
  accounts: {
    path: "/accounts",
    title: "Accounts",
  },
  sales: {
    path: "/sales",
    title: "Sales",
  },
  aliflailaIndividualUsers: {
    path: '/individuals',
    title: 'Individuals'
  },
  individualDetail: {
    path: '/individual/:id',
    title: 'Individual Details'
  },
  parentUsers: {
    path: '/parents',
    title: 'Parents'
  },
  parentDetail: {
    path: '/parent/:id',
    title: 'Parent Details'
  },
  subscriptionRequest: {
    path: "/subscription-request",
    title: "Subscription Request",
  },
  subscriptions: {
    path: "/subscriptions",
    title: "Subscriptions",
  },
  customNotifications: {
    path: "/custom-notifications",
    title: "Notifications",
  },
  termsAndCondition: {
    path: "/terms-and-conditions",
    title: "Terms & Condition",
  },
  privacyPolicy: {
    path: "/privacy-policy",
    title: "Privacy Policy",
  },
  newsletterSubscription: {
    path: "/newsletter-subscriptions",
    title: 'Newsletter Subscriptions'
  },
  transactionHistory: {
    path: "/transaction-history",
    title: 'Transaction History'
  },
  banners: {
    path: "/banners",
    title: 'Banners'
  },
  notifications: {
    path: "/notifications",
    title: 'Notifications'
  }
};
export { routeConstant };
