import { Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Icons } from "assets";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
  open,
  handleClose,
  selectedItem,
}: any) {
  const [active, setActive] = useState<any>(null);

  console.log("selectedItem", selectedItem);

  const parent = [
    {
      label: "Created at",
      value: moment(selectedItem?.created_at).format("DD MMM, YYYY"),
    },
    {
      label: "Total Kids",
      value: selectedItem?.total_kids,
    },
    { label: "Contact No", value: selectedItem?.parent?.phone },
    {
      label: "Status",
      value: selectedItem?.is_blocked ? "Deactive" : "Active",
    },
    { label: "Plan Active", value: selectedItem?.current_plan ? "Yes" : "No" },
  ];

  const parent_active = [
    {
      label: "Created at",
      value: moment(selectedItem?.created_at).format("MMM DD, YYYY"),
    },
    {
      label: "Total Kids",
      value: selectedItem?.total_kids,
    },
    { label: "Contact No", value: selectedItem?.parent?.phone },
    {
      label: "Status",
      value: selectedItem?.is_blocked ? "Deactive" : "Active",
    },
    { label: "Plan Active", value: selectedItem?.current_plan ? "Yes" : "No" },
    {
      label: "Plan Expiry",
      value: moment(selectedItem?.current_plan?.expiry_date).format(
        "MMM DD, YYYY"
      ),
    },
    {
      label: "Coins",
      value: selectedItem?.current_plan ? selectedItem?.current_wallet?.remaining_coins : "",
    },
    {
      label: "Duration",
      value: selectedItem?.current_plan?.duration + " Months",
    },
  ];

  const kid = [
    {
      label: "Created at",
      value: moment(selectedItem?.created_at).format("MMM DD, YYYY"),
    },
    {
      label: "Birth Year",
      value: selectedItem?.reader?.birth_year.split("-")[0],
    },
    {
      label: "Grade",
      value: selectedItem?.reader?.grade
        ? selectedItem?.reader?.grade
        : "Not Provided",
    },
    { label: "Age", value: selectedItem?.reader?.age },
    {
      label: "School",
      value: selectedItem?.reader?.school
        ? selectedItem?.reader?.school
        : "Not Provided",
    },
    {
      label: "Status",
      value: selectedItem?.is_blocked ? "Deactive" : "Active",
    },
    { label: "Plan Active", value: selectedItem?.current_plan ? "Yes" : "No" },
  ];
  const kid_active = [
    {
      label: "Created at",
      value: moment(selectedItem?.created_at).format("MMM DD, YYYY"),
    },
    {
      label: "Birth Year",
      value: selectedItem?.reader?.birth_year.split("-")[0],
    },
    { label: "Grade", value: selectedItem?.reader?.grade },
    { label: "Age", value: selectedItem?.reader?.age },
    { label: "School", value: selectedItem?.reader?.school },
    {
      label: "Status",
      value: selectedItem?.is_blocked ? "Deactive" : "Active",
    },
    { label: "Plan Active", value: selectedItem?.current_plan ? "Yes" : "No" },
    {
      label: "Plan Expiry",
      value: moment(selectedItem?.current_plan?.expiry_date).format(
        "MMM DD, YYYY"
      ),
    },
    {
      label: "Coins",
      value: selectedItem?.current_plan
        ? selectedItem?.current_wallet?.remaining_coins
        : "0",
    },
    {
      label: "Duration",
      value: selectedItem?.current_plan?.duration + " Months",
    },
  ];

  useEffect(() => {
    if (selectedItem?.parent) {
      if (selectedItem?.current_plan) {
        setActive(parent_active);
      } else {
        setActive(parent);
      }
    }

    if (selectedItem?.reader) {
      if (selectedItem?.current_plan) {
        setActive(kid_active);
      } else {
        setActive(kid);
      }
    }
    // eslint-disable-next-line
  }, [open]);

  console.log("selected", selectedItem);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: (theme) => theme.spacing(90),
              position: "relative",
              padding: "24px",
            },
          },
        }}
      >
        {" "}
        <div className={styles.crossIcon}>
          <Icons.CrossIcon onClick={() => handleClose()} />
        </div>
        <div className={styles.title}>
          {selectedItem?.parent
            ? selectedItem?.parent?.full_name
            : selectedItem?.reader?.full_name
            ? selectedItem?.reader?.full_name
            : ""}
        </div>
        <Grid container sx={{ marginTop: "20px" }}>
          {active?.map((item: any) => {
            return (
              <Grid xs={12} md={6} marginBottom={"10px"}>
                <div className={styles.headingContainer}>
                  <span>{item?.label}</span>
                  <span>{item?.value}</span>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </Dialog>
    </React.Fragment>
  );
}
